import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable, map } from "rxjs";
import { ModificationModel } from "@app/pages/modifications/utils/model";
import { IResponseDTO } from '@shared/interfaces';

@Injectable({
  providedIn: "root",
})
export class ModificationService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getModifications(
    property: string = "",
    limit: number = 10,
    page: number = 1,
    filter: any = {}
  ): Observable<IResponseDTO<any[]>> {
    const body = {
      property,
      limit,
      page,
    };

    return this.http.post<IResponseDTO<any[]>>(
      `${environment.apiUrl}/requests/search`,
      body,
      {
        withCredentials: true,
      }
    );
  }

  public getResidentModifications(
    limit: number = 10,
    page: number = 1
  ): Observable<IResponseDTO<any[]>> {
    const body = {
      limit,
      page,
    };

    return this.http.post<IResponseDTO<any[]>>(
      `${environment.apiUrl}/resident/requests/search`,
      body,
      {
        withCredentials: true,
      }
    );
  }

  public getModification = (id: string): Observable<any> => {
    return this.http.get<any>(`${this.apiUrl}/requests/${id}`, {
      withCredentials: true,
    });
  };

  public getUnitsByAssociationId = (id: string): Observable<any> => {
    const url = `${this.apiUrl}/associations/${id}/units`;
    return this.http.get<any>(url, { withCredentials: true });
  };

  public getAssociations = (): Observable<any> => {
    const url = `${this.apiUrl}/associations`;
    return this.http.get<any[]>(url, { withCredentials: true });
  };

  public getCategories = (): Observable<ModificationModel> => {
    const url = `${this.apiUrl}/categories/requests`;
    return this.http
      .get<ModificationModel>(url, { withCredentials: true })
      .pipe(
        map((res: any) => {
          res = new ModificationModel().convert(res);
          return res;
        })
      );
  };

  public createModification(body: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/requests`, body);
  }

  deleteModification<Resp = any>(id: string) {
    return this.http.delete<Resp>(`${this.apiUrl}/requests/${id}`);
  }
}
